import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: '',
 //   pathMatch: 'full',
 //   redirectTo: 'home',
    component: HomeComponent,
  },
  {
    path: '',
    loadChildren: () =>
      import('./home/home.module').then(
        (m) => m.HomeModule
      ),
  },

  {
    path: 'itinerary',
    loadChildren: () =>
      import('./itinerary/itinerary.module').then(
        (m) => m.ItineraryModule
      ),
  },
  {
    path: 'tour',
    loadChildren: () =>
      import('./tour/tour.module').then(
        (m) => m.TourModule
      ),
  },

  {
    path: 'payment',
    loadChildren: () =>
      import('./payment/payment.module').then(
        (m) => m.PaymentModule
      ),
  },
  {
    path: 'booking',
    loadChildren: () =>
      import('./booking/booking.module').then(
        (m) => m.BookingModule
      ),
  },
  {
    path: 'confirmbooking',
    loadChildren: () =>
      import('./confirmbooking/confirmbooking.module').then(
        (m) => m.ConfirmBookingModule
      ),
  },

  {
    path: 'aboutus',
    loadChildren: () =>
      import('./information/aboutus/aboutus.module').then(
        (m) => m.AboutusModule
      ),
  },
  {
    path: 'contactus',
    loadChildren: () =>
      import('./information/contactus/contactus.module').then(
        (m) => m.ContactusModule
      ),
  },
  {
    path: 'paymentsuccess',
    loadChildren: () =>
      import('./paymentsuccess/paymentsuccess.module').then(
        (m) => m.PaymentSuccessModule
      ),
  },

  {
    path: 'news',
    loadChildren: () =>
      import('./information/news/news.module').then(
        (m) => m.NewsModule
      ),
   
  },
  {
    path: 'jobs',
    loadChildren: () =>
      import('./information/jobs/jobs.module').then(
        (m) => m.JobsModule
      ),
   
  },

  {
    path: 'info',
    loadChildren: () =>
      import('./information/info/info.module').then(
        (m) => m.InfoModule
      ),
   
  },

  {
    path: 'tax',
    loadChildren: () =>
      import('./information/tax/tax.module').then(
        (m) => m.TaxModule
      ),
   
  },

  {
    path: 'tic',
    loadChildren: () =>
      import('./information/tic/tic.module').then(
        (m) => m.TicModule
      ),
   
  },
  {
    path: 'tripshoot',
    loadChildren: () =>
      import('./information/tripshoot/tripshoot.module').then(
        (m) => m.TripShootModule
      ),
   
  },
  {
    path: 'lecture',
    loadChildren: () =>
      import('./information/lecture/lecture.module').then(
        (m) => m.LectureModule
      ),
   
  }, 
  {
    path: 'incentive',
    loadChildren: () =>
      import('./information/incentive/incentive.module').then(
        (m) => m.IncentiveModule
      ),
  },
  {
    path: '**',
    redirectTo: 'error/error404',
    pathMatch: 'full',
  },
];
  

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
