import { Component,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

schemas: [ CUSTOM_ELEMENTS_SCHEMA ];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  openFacebook(){
    window.open("https://www.facebook.com/MorningStarTravelHK","_blank");
  }

}
