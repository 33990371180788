<c-carousel  [animate]="true" transition="crossfade" [interval]="3000" >
    <c-carousel-indicators></c-carousel-indicators>
    <c-carousel-inner>
      <c-carousel-item *ngFor="let slide of slides">
        <a href="{{slide.title}}" >
        <img
          [src]="slide.src"
          alt="{{slide.subtitle}}"
          class="d-block w-100"
          loading="lazy" />
          </a>

      </c-carousel-item>
    </c-carousel-inner>
    <c-carousel-control [routerLink] caption="<" direction="prev" style="margin-left:10px; color:black"></c-carousel-control>
    <c-carousel-control [routerLink] caption=">" direction="next" style="margin-right:10px; color:black"></c-carousel-control>
  </c-carousel>
  