import { Component, OnInit } from '@angular/core';
import { CarouselModule } from '@coreui/angular';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  slides: any[] = new Array(12).fill({id: -1, src: '', title: '', subtitle: ''});

  constructor() {}

  ngOnInit(): void {
    this.slides[0] = {
      src: './assets/img/slider/tourads1.jpeg',
      title: '/itinerary?itinCode=BIEK11D',

    };
    this.slides[1] = {
      src: './assets/img/slider/tourads2.jpeg',
      title: '/itinerary?itinCode=LWAA06S',
    }
    this.slides[2] = {
      src: './assets/img/slider/tourads3.jpeg',
      title: '/itinerary?itinCode=MBAC06E',
    }
    this.slides[3] = {
      src: './assets/img/slider/tourads4.jpeg',
      title: '/itinerary?itinCode=BMEK09D',
    }
    this.slides[4] = {
      src: './assets/img/slider/tourads5.jpeg',
      title: '/itinerary?itinCode=EEBB11D',
    }
    this.slides[5] = {
      src: './assets/img/slider/tourads6.jpeg',
      title: '/itinerary?itinCode=FSSS09D',
    }
    this.slides[6] = {
      src: './assets/img/slider/tourads7.jpeg',
      title: '/itinerary?itinCode=EEZB16D',
    }
    this.slides[7] = {
      src: './assets/img/slider/tourads8.jpeg',
      title: '/itinerary?itinCode=KKCT04S',
    }
    this.slides[8] = {
      src: './assets/img/slider/tourads9.jpeg',
      title: '/itinerary?itinCode=LSSB16D',
    }
    this.slides[9] = {
      src: './assets/img/slider/tourads10.jpeg',
      title: '/itinerary?itinCode=LCSS06S',
    }
    this.slides[10] = {
      src: './assets/img/slider/tourads11.jpeg',
      title: '/itinerary?itinCode=TCCA07E',
    }
    this.slides[11] = {
      src: './assets/img/slider/tourads12.jpeg',
      title: '/itinerary?itinCode=LRSH08D',
    }

  }

  onItemChange($event: any): void {
    console.log('Carousel onItemChange', $event);
  }
}